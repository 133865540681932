import { render, staticRenderFns } from "./ResetPassword.vue?vue&type=template&id=ea8668c4&scoped=true"
import script from "./ResetPassword.vue?vue&type=script&lang=js"
export * from "./ResetPassword.vue?vue&type=script&lang=js"
import style0 from "./ResetPassword.vue?vue&type=style&index=0&id=ea8668c4&prod&lang=css"
import style1 from "./ResetPassword.vue?vue&type=style&index=1&id=ea8668c4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ea8668c4",
  null
  
)

export default component.exports